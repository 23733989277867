.voucherNameContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  & .voucherName {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #3e444a;

    @media screen and (max-width: 650px) {
      font-size: 22px;
    }

    @media screen and (max-width: 450px) {
      font-size: 19px;
    }

    @media screen and (max-width: 400px) {
      font-size: 16px;
    }
  }
}

.modalCloseIcon {
  cursor: pointer;
}

.selectPlaceholder {
  color: rgba(0, 0, 0, 0.38);
}