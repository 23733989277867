.pageContainer {
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 85vh;
}

.pageTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px 0 24px 0;
  padding: 0 10px;

  .nav {
    display: flex;
    align-items: center;
    grid-gap: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;

    &_link {
      position: relative;
      color: #3e444a;
    }

    &_activeLink {
      color: #0090d1;
      position: relative;
      border-bottom: 2px solid #0090d1;
    }
  }

  & .pageMainTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 40px;
    color: #3e444a;

    @media screen and (max-width: 400px) {
      font-size: 22px;
    }
  }
}

.pageContent {
  padding: 24px;
  background-color: #fff;
  border-radius: 16px;
  flex-grow: 1;
  margin: 0 10px;
}

.box_inputSearch {
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 10px;
    right: 17px;
  }
}

.btn_filter {
  height: 40px;
  min-width: 200px;
  padding-inline: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  background: #f0f8ff;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #0090d1;
  cursor: pointer;
}
